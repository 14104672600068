@import '../../../sass/colors';

.wrapper {
  flex-direction: column;
  width: 100%;
  display: flex;
  .btnsWrapper {
    display: flex;
    .back,.next {
      width: 42rem;
      height: 7rem;
      border: 0;
      outline: 0;
      background: #fdbb11;
      color: white;
      font-size: 1.8rem;
      margin: 0;
      font-weight: 900;
      border-radius: 5px;
      box-shadow: 0 20px 43px -12px rgba(253, 187, 17, 0.25);
      transition: all .2s;
      img {
        width: 3rem;
        height: 3rem; } }
    .back {
      width: 13.6rem;
      box-shadow: none;
      background: white;
      color: #fdbb11;
      border: 1px solid #fdbb11; }
    .back:hover {
      background: #fdbb11;
      color: white; }
    .next:hover {
      box-shadow: 0 10px 18px -6px rgba(253,187,17,0.25); } } }
