.loadingWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  .override {
    display: block;
    margin: 0 auto;
    border-color: red; } }
