

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 5.5rem 2.6rem;
  // & > *
  //// margin: 0
  .urlWrapper {
    margin: 3rem 0 0;
    padding: 2.4rem;
    border-radius: 10px;
    box-shadow: 1px 2px 10px 0 rgba(126, 91, 1, 0.1);
    display: flex;
    flex-direction: column;
    background-color: #fff;
    .urlT {
      color: #222;
      font-size: 2.4rem;
      font-weight: 900;
      letter-spacing: .64px;
      text-align: center; }
    .urlP {
      color: #2d2d2d;
      font-size: 1.6rem;
      margin: 0 0 1.5rem; }
    .socialIcons {
      display: flex;
      flex-wrap: wrap;
      .socialIcon {
        cursor: pointer;
        background: #fdbb11;
        border-radius: 50%;
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 2rem 2rem 0;
        &.disabled {
          cursor: not-allowed;
          background: #e2e2e2; }
        img {
          width: 2.54rem;
          height: 2.54rem; } } }
    .find {
      color: #2d2d2d;
      font-size: 1.6rem;
      font-weight: 900;
      margin: 2rem 0 1rem; }
    .clipWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      .label {
        font-size: 1.6rem;
        font-weight: 900;
        margin: 0 0 1rem;
        color: #2d2d2d; }
      .input {
        border-radius: 5px;
        width: 100%;
        display: flex;
        height: 5.5rem;
        .inputWrapper {
          width: 70%;
          position: relative;
          .url {
            white-space: nowrap;
            border-radius: 5px 0 0 5px;
            outline: 0;
            padding: 0 2rem;
            height: 100%;
            width: 100%;
            box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
            border: solid 1px #e3e3e3;
            background-color: #ebebeb;
            display: flex;
            text-wrap: none;
            flex-wrap: nowrap !important;
            overflow: hidden !important;
            align-items: center;
            font-size: 1.6rem;
            font-weight: 500;
            color: #2d2d2d; }
          .shadow {
            position: absolute;
            right: 0;
            top: 0;
            background-image: linear-gradient(to left, #ebebeb, rgba(235, 235, 235, 0.37));
            width: 6rem;
            height: 100%; } }
        .clip {
          border-radius: 0 5px 5px 0;
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fdbb11;
          cursor: pointer;
          .icon {
            width: 1.6rem;
            height: 1.6rem;
            margin: 0 .4rem; }
          .text {
            margin: 0 .4rem;
            font-size: 1.4rem;
            font-weight: 900;
            color: white; } } } } }

  .line {
    height: 1px;
    background: #ebebeb;
    margin: 3rem 0; }
  .para {
    color: #2d2d2d;
    font-size: 1.6rem;
    margin: 0 0 1.6rem; }
  .title {
    font-size: 2.4rem;
    font-weight: 900;
    color: #222;
    letter-spacing: .64px; }
  .edit {
    border-radius: 29px;
    border: solid 1px #fdbb11;
    background: #ffffff;
    color: #fdbb11;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18.4rem;
    height: 5.8rem;
    font-size: 1.6rem;
    font-weight: 900;
    align-self: flex-end;
    img {
      width: 1.7rem;
      margin: 0 .4rem;
      height: 1.7rem; }
    p {
      margin: 0 .4rem; } }
  .edit:disabled {
    opacity: .5;
    cursor: not-allowed; } }
