.OTOCardwrapper {
  min-width: 37rem;
  width: 31%;
  height: 32rem;
  padding: 2.4rem;
  border-radius: 10px;
  box-shadow: 1px 2px 10px 0 rgba(126, 91, 1, .1);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: space-between;
  margin: 3rem 0 0 3rem;


  &:nth-child(1),
  &:nth-child(3n+1) {
    margin-left: 0; }

  &:only-child {
    margin: 0; }
  // &:nth-child(3n)
  //   margin-right: 0

  // &:not(:last-of-type)

  .upperContent {
    .campaignName {
      min-height: 4.5rem;
      font-size: 2.4rem;
      font-weight: 900;
      letter-spacing: .64px;
      color: #222;
      margin-bottom: 1.6rem;
      overflow: hidden;
      text-overflow: ellipsis; }

    .edit {
      z-index: 11213;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f6f6f6;
      min-width: 4.6rem;
      height: 4.6rem;
      border-radius: 50%;

      img {
        width: 2.4rem;
        height: 2.4rem; } }

    .campaignStatsWrapper {
      display: flex;
      justify-content: space-between;

      .timeStatus {
        display: flex;
        align-items: center;
        line-height: 1;

        .time {
          margin-right: .8rem;
          width: 4rem;
          height: 4rem; }

        .daysLeft {
          .daysNumber {
            font-size: 2.2rem;
            font-weight: 900;
            letter-spacing: .59px;
            color: #2d2d2d; } } }

      .campaignStatus {
        .statusBadge {
          font-size: 1.4rem;
          font-weight: 500;
          width: 8.5rem;
          height: 2.3rem;
          border-radius: 14.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: .4rem;

          &.running {
            color: #197741;
            background-color: rgba(168, 238, 193, .5); }

          &.expired {
            color: #871b1b;
            background-color: rgba(241, 168, 169, .5); } } }

      .subTitle {
        font-size: 1.4rem;
        font-weight: 500;
        color: #2d2d2d; } } }

  .lowerPart {
    .campaignCustomersStats {
      display: flex;
      justify-content: space-between;

      .campaignCustomersNumber,
      .maxCampaignCustomers {
        .number {
          font-size: 2.4rem;
          font-weight: 900;
          letter-spacing: .64px;
          color: #2d2d2d; }

        .subTitle {
          font-size: 1.6rem;
          font-weight: 500;
          color: #2d2d2d; } } } } }
