@import '../../sass/colors';

.navBar {
  width: 21.5rem;
  height: 100vh;
  background: #1c0432;
  display: flex;
  position: fixed;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    .title {
      color: #9b9b9b;
      font-size: 1.4rem;
      font-weight: 500;
      align-self: flex-start;
      margin: 0 3rem 1rem;
      text-transform: uppercase;
      letter-spacing: 1px; } }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem; } }

.separator {
  width: 100%;
  height: 1px;
  margin: 0 0 2rem;
  background: #363636; }
.footer {
  color: #6b6961;
  margin: 1.5rem 0;
  text-align: center;
  font-size: 1.2rem; }

.userDD {
  background: #1c0432 !important;
  border: 0 !important;
  outline: 0 !important;
  margin: .5rem 0 !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: none !important;
  * {
    margin: 0; }
  .dd {
    margin: 0 1.5rem;
    width: 7px;
    height: 15px; }
  .brandImg {
    width: 3.7rem;
    height: 3.7rem;
    border: solid 1px #2d2d2d;
    border-radius: 50%; }
  .userData {
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    .userName {
      color: #6b6961;
      font-weight: 500;
      font-size: 1.2rem; }
    .brandName {
      color: white;
      font-size: 1.4rem;
      font-weight: 500; } } }

.menu {
  width: 100%;
  margin: 0 -5px;
  padding: 0 !important;
  top: 0 !important;
  .item {
    padding: 1rem 2rem;
    font-size: 1.6rem;
    font-weight: 500;
    outline: 0 !important;
    background: white; }
  .item:hover {
    background: rgba(254,190,25,.1); } }
