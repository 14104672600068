@import "../../sass/colors";


.inputWrapper,.taggedInputWrapper {
  display: flex;
  width: 100%;
  margin: 0;
  justify-content: flex-start !important;
  flex-direction: column; }
.taggedInputWrapper {
  width: 55%;
  .tagWrapper {
    display: flex;
    align-items: center;
    .tag, .arTag {
      margin: 0;
      font-size: 1.4rem;
      height: 5.5rem;
      color: #2D2D2D;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 2rem;
      border-radius: 0 1rem 1rem 0;
      background: #E3E3E3; }
    .arTag {
      border-radius: 1rem 0 0 1rem !important; } } }
.label {
  display: flex;
  color: #2D2D2D;
  flex: 1;
  position: relative;
  justify-content: space-between !important;
  font-weight: 700;
  font-size: 1.6rem;
  span {
    margin: 0 !important; } }
.formError {
  font-weight: bold !important;
  font-size: 1.6rem !important;
  color: #FF0606 !important; }

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  background-color: white;
  border: 1px solid $color-border;
  border-radius: 5px;
  margin-bottom: 5rem;
  .textWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: .5rem 2.5rem;
    border-bottom: .5px solid $color-border;
    h1 {
      font-size: 2rem;
      font-weight: 600; }
    .countersWrapper {
      font-size: 1.5rem;
      font-weight: bold;
      margin-top: .6rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end; } }
  .textAreaWrapper {
    padding: 2rem;
    .textArea {
      width: 100%;
      height: 25rem;
      color: $color-black-text;
      border: .5px solid $color-border;
      resize: none;
      font-size: 2rem;
      padding: 2rem; }
    .textArea:disabled {
      background-color: #F3F3F3; } } }

.formError {
  font-size: 1.6rem;
  color: red;
  margin: 0 3rem 2rem; }

.fullTimeWrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid $color-border;
  border-radius: 5px;
  user-select: none;
  .textWrapper {
    padding: 2rem 3rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-border;
    h2 {
      font-size: 2rem;
      font-weight: 600;
      margin: 0; } }
  .pickersWrapper {
    padding: 2rem 2rem;
    display: flex;
    flex-wrap: wrap;
    .datePicker {
      display: flex;
      width: 42.5rem;
      margin: 1rem;
      align-items: center;
      position: relative;
      .date {
        height: 5rem;
        padding: 2rem;
        font-size: 1.6rem !important;
        width: 42.5rem;
        color: #B1B1B1; }
      .date:disabled {
        background-color: #F3F3F3; }
      .icon {
        position: absolute;
        right: 0; } }
    .timePicker {
      display: flex;
      margin: 0 1rem;
      align-items: center;
      width: 25rem;
      position: relative;
      input,.time {
        height: 5rem;
        padding: 2rem;
        font-size: 1.6rem !important;
        width: 25rem;
        color: #B1B1B1; }
      input:disabled {
        background-color: #F3F3F3; }
      .time:disabled {
        background-color: #F3F3F3; }
      .icon {
        position: absolute;
        right: 0; } } } }


.icon {
  font-size: 2rem;
  margin: 0 1rem; }

.chipsFieldWrapper {
  display: flex;
  min-height: 14rem;
  min-width: 48%;
  margin: 2rem auto;
  flex-direction: column;
  background-color: white;
  border: 1px solid $color-border;
  border-radius: 5px;
  @media only screen and (max-width: $bg-largest-2) {
    min-width: 58rem; }
  .textWrapper {
    padding: 2rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-border;
    h2 {
      font-size: 2rem;
      font-weight: 600; } }
  .chipsWrapper {
    padding: 2rem;
    .error {
      font-size: 1.6rem;
      margin: 1rem 0;
      color: red; } } }

.twoInputsWrapper {
  display: flex;
  width: 48%;
  margin: 2rem auto;
  flex-direction: column;
  min-height: 14rem;
  background-color: white;
  border: 1px solid $color-border;
  border-radius: 5px;
  @media only screen and (max-width: $bg-largest-2) {
    width: 58rem; }
  .textWrapper {
    padding: 2rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-border;
    h2 {
      font-size: 2rem;
      font-weight: 600;
      margin: 0; }
    .icon {
      padding: 0;
      font-size: 2rem;
      margin: 0 1rem; } }
  .valueWrapper {
    padding: 1.5rem 2rem;
    display: flex;

    .paragraph1,.paragraph2 {
      background-color: white;
      font-size: 2rem !important;
      border: 1px solid $color-border;
      height: 5rem;
      padding: 1rem 2rem;
      border-radius: .4rem;
      color: $color-black-text !important;
      width: 20%;
      text-align: center; }

    .paragraph1 {
      margin-right: 2rem; }

    input {
      height: 5rem;
      width: 35%; } } }

input {
  width: 35%;
  background-color: white;
  font-size: 2rem !important;
  border: 1px solid $color-border;
  height: 5rem;
  padding: 1rem 2rem;
  border-radius: .4rem;
  color: $color-black-text !important; }
input:disabled {
  background-color: #F3F3F3; }

input::placeholder {
  color: #989A97; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }
input:disabled {
  background-color: #F3F3F3; }
.DDWrapper {
  display: flex;
  width: 48%;
  margin: 2rem auto;
  flex-direction: column;
  min-height: 14rem;
  background-color: white;
  border: 1px solid $color-border;
  border-radius: 5px;
  @media only screen and (max-width: $bg-largest-2) {
    width: 58rem; }
  .textWrapper {
    padding: 2rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-border;
    h2 {
      font-size: 2rem;
      font-weight: 600;
      margin: 0; } }
  .chipsWrapper {
    padding: 2rem; } }

.switch {
  background-color: white !important;
  width: 100%;
  font-size: 2rem !important;
  border: 1px solid $color-border !important;
  height: 5rem;
  text-align: left !important;
  border-radius: .4rem !important;
  opacity: 1 !important;
  color: black !important; }
.switch:disabled {
  background-color: #F3F3F3 !important; }

.menuWrapper {
  width: 100%;
  .item {
    padding: 1rem;
    font-size: 2rem; }
  .item:hover {
    background-color: $color-shopx-main-theme; } }



.input {
  height: 5.5rem;
  width: 100%;
  border-radius: 1rem;
  outline: none;
  font-size: 3.5rem;
  border: 1px solid $color-border;
  padding: 1rem;
  color: $color-black-text;
  text-align: left; }
.input:disabled {
  background-color: #F3F3F3; }


.datePickerWrapper {
  display: flex;
  flex-direction: column;
  .datePicker {
    display: flex;
    align-items: center;
    padding: 0;
    position: relative;
    width: 100%;
    div:first-child {
      width: 100%;
      div {
        bottom: 6rem; }
      .dateInput {
        width: inherit;
        height: 6rem;
        border-radius: 1rem;
        padding: 0 1rem;
        font-size: 1.8rem !important;
        border: 1px solid $color-border !important;
        color: black; }
      input:disabled {
        background-color: #F3F3F3; }
      .time:disabled {
        background-color: #F3F3F3; } }
    .icon {
      position: absolute;
      right: 0;
      font-size: 2rem;
      padding: 0 1rem; } } }


.pointsWrapper {
  display: flex;
  align-items: center;
  .pointsToMoney {
    margin: 0 1rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: #fdbb11; } }


.colorPickerWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin: 0;
  .wrapper2 {
    display: flex;
    align-items: center;
    .value {
      height: 4.5rem;
      width: 9rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 1rem;
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid #e3e3e3;
      font-size: 1.6rem;
      color: #2d2d2d; }
    .box {
      cursor: pointer;
      width: 4.5rem;
      border: solid 1px #2d2d2d;
      height: 4.5rem;
      border-radius: 5px; } }
  .label {
    flex-direction: column !important;
    .note {
      color: #7d7d7d;
      margin: 2rem 0;
      font-size: 1.8rem;
      font-weight: normal; } } }

.textAreaWrapper {
  display: flex;
  width: 100%;
  margin: 1rem 0;
  flex-direction: column; }
.textAreaLabel {
  position: relative;
  margin: 0;
  color: #606977;
  font-size: 1.6rem;
  span {
    margin: 0; }
  .formError {
    color: #FF0606; } }
.textArea {
  height: 15rem;
  outline: none;
  resize: none;
  border-radius: 5px;
  font-size: 1.6rem;
  padding: 1.5rem 2rem;
  color: $color-black-text;
  border: 1px solid $color-border; }
.textArea::placeholder {
  color: #2d2d2d;
  font-size: 1.6rem;
  font-weight: 500; }

.notice {
  font-size: 1.5rem;
  color: #2d2d2d; }



.overlay {
  background: rgba(0,0,0,.3);
  z-index: 3331 !important;
  .modal {
    z-index: 3331 !important;
    padding: 0;
    min-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 60rem;
    border-radius: 20px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px #979797;
    overflow: hidden;
    * {
      margin: 0; }
    .btnWrapper {
      width: 100%;
      justify-content: flex-end;
      display: flex;
      margin: 2rem 0;
      &.rtl {
        justify-content: flex-start;
        .btnCancel {
          margin: 0 1.5rem; }
        .btnSave {
          margin: 0; } }
      .btnCancel,.btnSave {
        outline: 0;
        background: white;
        border: 1px solid #fdbb11;
        padding: 1rem 1.5rem;
        font-size: 1.4rem;
        font-weight: 900;
        color: #fdbb11;
        border-radius: 4rem; }
      .btnSave {
        margin: 0 1.5rem;
        color: white;
        border: 0;
        background: #fdbb11; } }
    .cropHead {
      color: #2D2D2D;
      font-weight: 500;
      font-size: 2.2rem;
      width: 96%;
      margin: 1rem 2rem; }
    .sliderWrapper {
      margin: 3rem 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .minScale {
        width: 2rem;
        height: 2rem; }
      .maxScale {
        width: 3rem;
        height: 3rem; }
      .slider {
        margin: 0 2rem;
        padding: 0 !important;
        display: flex;
        width: 50%;
        background-color: white !important; } } } }


.uploadPart {
  display: flex;
  .img {
    width: 8.5rem;
    height: 8.5rem; }
  .uploadWrapper {
    display: flex;
    margin: 0 1.8rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .max {
      font-size: 1.2rem;
      color: #A4A4A4;
      margin: 0 0 1rem; }
    .uploadBtn,.uploadFBtn {
      background: white;
      outline: none !important;
      color: $color-shopx-main-theme;
      border: 1px solid $color-shopx-main-theme;
      font-size: 1.4rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 13rem;
      height: 4rem;
      border-radius: 3rem; }
    .uploadFBtn {
      background: $color-shopx-main-theme !important;
      border: 0 !important;
      color: white !important; } } }
