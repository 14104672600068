.mobile {
  width: 280px;
  height: 564px;
  padding: 10px;
  background: #2d2d2d;
  border-radius: 30px;
  position: relative;

  .notch {
    background: #2d2d2d;
    position: absolute;
    top: 0;
    left: 25%;
    width: 50%;
    height: 20px;
    z-index: 2;
    border-radius: 0 0 15px 15px; } }
