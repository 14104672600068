.title {
  font-size: 2.4rem;
  font-weight: 900;
  letter-spacing: .64px;
  color: #222; }

.subTitle {
  font-size: 1.6rem;
  color: #2d2d2d;
  margin-bottom: 2rem; }

.wrapper {
  width: 32%;
  border-radius: 10px;
  box-shadow: 1px 2px 10px 0 rgba(2, 10, 39, .1);
  background-color: #fff;
  padding: 2.4rem;
  height: max-content;


  .chartWrapper {
    margin-bottom: 2rem; }

  .statsList {
    .stat {
      padding: 1.7rem 0 .9rem;
      border-bottom: solid 1px rgba(69, 57, 55, .3);
      display: flex;
      justify-content: space-between;

      &:last-of-type {
        border-bottom: 0; }

      .statNameAndDot {
        display: flex;
        align-items: center;

        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%; }

        .statName {
          font-size: 1.6rem;
          color: #453937;
          margin: 0 1.6rem; } }

      .statValues {
        display: flex;

        .value {
          font-size: 1.6rem;
          color: #2d2d2d;
          margin: 0 1.8rem; }

        .percentage {
          min-width: 4rem;
          font-size: 16px;
          color: #453937; } } } } }




