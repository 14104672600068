@import "../../../sass/colors";

.wrapper {
  border: none;
  box-shadow: 0 12px 24px rgba(0, 23, 59, 0.1);
  padding: 1.5rem 2rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 28rem;
  height: 23rem;
  border-radius: 5px;
  margin: 2.5rem;
  @media only screen and (max-width: $bg-largest) {
    width: 25rem; }
  .aboveWrapper {
    align-self: flex-start;
    .for {
      align-self: flex-start;
      font-size: 1.4rem;
      color: #2d2d2d; }
    .header {
      align-self: flex-start;
      .title {
        font-size: 1.6rem;
        font-weight: bold; }
      .off {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        .offLogo {
          margin-top: -.3rem;
          width: 1.5rem;
          height: 1.5rem; }
        .offText {
          font-size: 1.6rem;
          color: #7ED321; } } } }
  .body {
    padding: 1.5rem 0;
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .points {
      line-height: 2rem;
      display: flex;
      flex-direction: column;
      font-size: 3rem;
      font-weight: bold;
      span {
        font-weight: initial;
        margin-left: -.5rem;
        font-size: 1.6rem; } }
    .sms {
      align-self: flex-end;
      display: flex;
      span {
        margin: -1rem 0 0 0;
        font-size: 1.2rem; }
      p {
        margin: 0;
        font-size: 1.2rem;
        span {
          margin: 0 .2rem;
          font-weight: bold;
          font-size: 2.2rem; } } } }
  .btn {
    background-image: linear-gradient(45deg, rgba(254,190,25,1) 0%, rgba(254,184,23,1) 46%, rgba(254,173,18,1) 100%);
    border: none;
    padding: 1rem 2rem;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 4rem;
    margin: 0 auto;
    color: white;
    transition: all .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    .arrow {
      margin: 0 .5rem;
      font-size: .8rem; } }

  .btn:hover {
    transform: translateY(-3px);
    box-shadow: $box-shadow-btn-hover; }
  .btn:active {
    box-shadow: $box-shadow-btn-active;
    transform: translateY(-1px); }
  .btn:disabled {
    background: #E3E3E3; } }
