@import "../../../sass/colors";

.wrapper {
  border-radius: 5px;
  border: 1px solid #b4b4b4;
  padding: 1rem;
  height: 17rem;
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  * {
    margin: 0; }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .line {
      width: 40%;
      margin: 0;
      height: 1px;
      background: #ebebeb; }
    .text {
      display: flex;
      line-height: 1.8rem;
      flex-direction: column;
      color: $color-black-text;
      font-size: 1.6rem;
      font-weight: 500; }
    .price {
      margin: 0 2rem;
      display: flex;
      align-items: flex-end;
      font-size: 1.8rem;
      font-weight: bold;
      color: #2d2d2d;
      line-height: 1.17; } }
  .body {
    display: flex;
    align-items: center;
    .giftImage {
      border-radius: 50%;
      width: 6rem;
      height: 6rem;
      object-fit: contain; }
    .textWrapper {
      display: flex;
      margin: 0 1rem;
      flex-direction: column;
      align-items: flex-start;
      .name {
        font-size: 2rem;
        font-weight: bold;
        color: $color-black-text; }
      .price {
        font-size: 1.6rem;
        color: #868686; } } } }
