@import "../../sass/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 3rem;
  .message {
    color: #222;
    margin: 1rem 0 2rem;
    width: 47rem;
    text-align: center;
    font-size: 2.2rem;
    font-weight: 500; }
  .img {
    width: 21rem; }
  .btn {
    width: 13.5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fdbb11;
    border: none;
    font-size: 1.4rem;
    font-weight: 900;
    border-radius: 3rem;
    color: white;
    transition: all .2s;
    outline: none !important;
    span {
      font-size: 2rem;
      font-weight: 400;
      margin: 0 .5rem; } }
  .btn:hover {
    transform: translateY(-3px); }
  .btn:active {
    transform: translateY(-1px); } }
