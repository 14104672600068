@import "../../../../sass/colors";


.right {
  left: 5rem; }
.left {
  right: 5rem; }

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 10rem 0 0;
  right: 1%;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e3e3e3;
  width: 100%;
  margin-top: -2rem;
  border-radius: 8px;
  background: white;
  transition: box-shadow 500ms; }

.tab {
  display: flex;
  align-items: center;
  .text {
    font-weight: 900;
    font-size: 2rem;
    margin: 0 .5rem; }
  span {
    margin: 0 .5rem; } }

.selectedGiftWrapper {
  display: flex;
  width: 100%;
  height: 10.1rem;
  border-radius: 10px;
  border: solid 1px #fdbb11;
  background: white;
  padding: 0 3rem;
  align-items: center;
  justify-content: space-between;
  margin: 4rem 0 0;
  .cardWrapper {
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0; }
  .sendSelectedGift {
    width: 44.5rem;
    height: 7.2rem;
    border-radius: 5px;
    box-shadow: 0 20px 43px -12px rgba(253, 187, 17, 0.25);
    background: #fdbb11;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 900;
    color: white;
    border: 0;
    outline: 0; } }
