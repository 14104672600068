@import "../../../sass/colors";

.wrapper {
  border-radius: 10px;
  box-shadow: 1px 2px 10px 0 rgba(126, 91, 1, 0.1);
  padding: 2.5rem;
  margin-top: 0.2rem;
  background: white;
  display: flex;
  flex-direction: column;
  width: 49%;
  height: 27rem;
  position: relative;
  .image {
    position: absolute;
    width: 13rem;
    height: 13rem;
    right: 0;
    top: 0; }
  .title {
    color: #222;
    font-size: 3.6rem;
    font-weight: 900;
    line-height: 1.22;
    margin: .5rem 0; }
  .subTitle {
    margin: 0 0 1.5rem;
    color: #2d2d2d;
    font-size: 1.6rem; }
  .isNew {
    color: #fdbb11;
    font-size: 1.4rem;
    font-weight: 900;
    line-height: 1.29;
    display: flex;
    align-items: center;
    .img {
      margin: 0 .5rem;
      width: 1.5rem;
      height: 1.5rem; } }
  .btnsWrapper {
    display: flex;
    .btn,.howIt {
      width: 13.5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fdbb11;
      border: none;
      font-size: 1.4rem;
      font-weight: 900;
      border-radius: 3rem;
      color: white;
      transition: all .2s;
      outline: none !important;
      span {
        font-size: 2rem;
        font-weight: 400;
        margin: 0 .5rem; } }
    .howIt {
      border: 1px solid #fdbb11;
      color: #fdbb11;
      background: white;
      margin: 0 1.5rem; }
    .btn:hover,.howIt:hover {
      transform: translateY(-3px); }
    .btn:active,.howIt:active {
      transform: translateY(-1px); } } }
