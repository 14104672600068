.wrapper {
  position: relative;
  width: max-content;

  .toggle {
    height: 6rem;
    font-size: 16px;
    font-weight: 500;
    color: #0f1316;
    display: flex;
    align-items: center;
    cursor: pointer;

    .selectedLabel {
      margin-right: 1rem; }

    .arrow {
      width: 1.5rem;
      height: 1.5rem; } }

  .menu {
    position: absolute;
    top: 6rem;
    left: 0;
    width: max-content;
    background-color: #fff;
    font-size: 1.6rem;
    z-index: 1;
    box-shadow: 1px 2px 10px 0 rgba(2, 10, 39, .1);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    min-width: 28rem;
    max-height: 30rem;

    // overflow: auto

    // padding: 2rem

    .selectItem {
      padding: 1rem;
      cursor: pointer; } } }
