@import "../../../sass/colors";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 4rem 0;
  .nameSection {
    display: flex;
    align-items: center;
    .arrow {
      font-size: 2rem;
      font-weight: bold; } }
  .editBTN {
    background-color: white;
    width: 22rem;
    height: 5rem;
    font-size: 1.6rem;
    border-radius: 4rem;
    transition: all .2s;
    outline: none !important;
    border: 1px solid $color-shopx-main-theme;
    color: $color-shopx-main-theme; } }
