@import "../../../../sass/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7rem;
    margin-bottom: 3rem;
    text-align: center;
    h1 {
      font-size: 4rem; }
    p {
      font-size: 1.8rem;
      color: $color-light-black-subheader;
      line-height: 1.5;
      margin: 1rem 0;
      font-weight: 400; } }
  .formWrapper {
    align-self: auto;
    width: 70%;
    display: flex;
    flex-direction: column;
    .error {
      font-size: 1.6rem;
      color: red; }
    h1 {
      font-size: 2.3rem;
      font-weight: 600;
      margin-bottom: .5rem; }
    .input {
      height: 7rem;
      width: 100%;
      outline: none;
      margin-bottom: 2.5rem;
      font-size: 3.5rem;
      border: 1px solid $color-border;
      padding: 1rem;
      color: $color-black-text;
      text-align: left; }
    .btn, .btnDisabled {
      align-self: flex-end;
      width: 17.2rem;
      height: 6rem;
      font-size: 1.8rem !important;
      border: none;
      outline: 0;
      color: white !important;
      border-radius: 4rem;
      cursor: pointer;
      transition: all .2s;
      outline: none !important; }
    .btnDisabled {
      background-color: #F3F3F3;
      color: black;
      cursor: not-allowed; }
    .btn {
      background-image: linear-gradient(45deg, rgba(254, 190, 25, 1) 0%, rgba(254, 184, 23, 1) 46%, rgba(254, 173, 18, 1) 100%); }

    .btn:hover {
      transform: translateY(-3px);
      box-shadow: $box-shadow-btn-hover; }
    .btn:active {
      box-shadow: $box-shadow-btn-active;
      transform: translateY(-1px); } } }

input::placeholder {
  color: #989A97; }
