@import "../../../../sass/colors";

.wrapper {
  width: 100%;
  display: flex;
  padding: 1rem 3rem;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  .line {
    width: 7rem;
    height: 2px;
    margin: 1rem 0 4rem;
    background: #fdbb11; }
  .header {
    font-size: 2.4rem;
    font-weight: bold; }
  .form {
    width: 100%;
    .input {
      width: 46.5rem; }
    .separator {
      margin: 3rem 0; }
    .btn {
      width: 44.5rem;
      height: 7.2rem;
      border-radius: 5px;
      box-shadow: 0 20px 43px -12px rgba(253, 187, 17, 0.25);
      background-color: #fdbb11;
      display: flex;
      margin: 2rem 0;
      align-items: center;
      justify-content: center;
      border: none !important;
      box-shadow: none !important;
      outline: 0 !important;
      transition: all .2s;
      font-size: 2rem;
      font-weight: 900;
      color: #ffffff; }


    // .btn:hover
    //   transform: translateY(-3px)
    //   box-shadow: $box-shadow-btn-hover !important
    .btn:active {
      transform: translateY(-1px); } } }
