@import "../../../sass/colors";


.wrapper {
  display: flex;
  .picker {
    height: 5rem;
    width: 100%;
    padding: 2rem;
    font-size: 1.6rem;
    color: #B1B1B1; }
  .icon {
    position: absolute;
    left: 22rem;
    font-size: 2rem;
    margin: 0 1rem;
    margin-top: .8rem; }


  .paragraph1,.paragraph2 {
    background-color: white;
    width: 35%;
    font-size: 2rem !important;
    border: 1px solid $color-border;
    height: 5rem;
    padding: 1rem 2rem;
    border-radius: .4rem;
    color: $color-grey-paragraph !important; }

  .paragraph1,.paragraph2 {
    width: 20%;
    text-align: center; }

  .paragraph1 {
    margin-right: 2rem; } }


