@import '../../../sass/colors';

.colorWidth {
  width: 20rem; }

.width {
  width: 50rem; }

.wrapper {
  display: flex;
  justify-content: space-between;

  .header {
    color: #222;
    font-size: 2.4rem;
    font-weight: 900;
    letter-spacing: .64px; }

  .editorWrapper,
  .previewWrapper {
    height: max-content;
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 60%;
    border: solid 1px #ebebeb;
    border-radius: 10px;
    padding: 3rem 2rem;
    margin: 6rem 0 5rem;


    .colorsWrapper {
      display: flex; } }

  .previewWrapper {
    width: 35%;
    padding: 2rem 2.4rem;
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    color: #2d2d2d;
    align-items: center;

    .header,
    .subTitle {
      align-self: flex-start; }

    .subTitle {
      width: 75%;
      font-size: 1.6rem;
      color: #2d2d2d; }

    .switchScreen {
      margin-top: 3rem;
      margin-bottom: 2rem;
      display: flex;
      height: 3.2rem;

      .switchBtn {
        outline: 0;
        border-radius: 5px;
        box-shadow: 0 1px 1px 0 rgba(126, 91, 1, .1);
        background-color: #fff;
        width: 4rem;
        border: 0;

        .chevron {
          width: 1rem;
          height: 1rem; } }

      .currentScreen {
        background-color: #fff5dc;
        color: #fdae11;
        font-size: 1.6rem;
        width: 12.8rem;
        display: flex;
        justify-content: center;
        align-items: center; } }

    .viewExampleLink {
      margin-top: 2.6rem;
      border-radius: 4rem;
      cursor: pointer;
      text-decoration: none;
      padding: 1.6rem 3.7rem;
      border: solid 1px #38c172;
      color: #38c172;
      font-size: 1.4rem;
      font-weight: 900;


      img {
        margin-left: .5rem;
        width: 1.4rem;
        height: 1.4rem; } } } }





