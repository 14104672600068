
.wrapper {
  margin: 6rem 0 0 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  .title {
    font-size: 4rem;
    padding: 0 5rem; }
  .desc {
    font-size: 1.8rem;
    padding: 0 5rem;
    color: #5D5D5D; }
  .pricesCover {
    margin: 4rem 0;
    padding: 0 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .footer {
    background-color: #2D2D2D;
    width: 100%;
    color: white;
    padding: 3rem;
    .f_title {
      font-size: 3.2rem; }
    .f_Q {
      margin: 2rem 0;
      .questionWrapper {
        margin: 2rem 0;
        p {
          font-size: 1.8rem;
          margin: 0; }
        span {
          font-size: 1.6rem;
          margin: 0;
          color: #9D9D9D; } } } } }
.rejectedText {
  margin: auto; }
