.NavigationItem {
    width: 100%;
    margin: 0 0 1.5rem;
}

.NavigationItem a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    padding: 1rem 0 1rem 3rem;
    cursor: pointer;
    width: 100%;
    opacity: .5;
    transition: all .2s;
    border-left: .5rem solid #1c0432;
}
.NavigationItem a p {
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0 1rem;
}
.NavigationItem a img {
  width: 2.5rem;
  height: 2.5rem;
}
.NavigationItem a:hover{
    color: white;
    text-decoration: none;
    background: rgba(72,19,118,.2);
    border-left: .5rem solid #fdbb11;
    opacity: 1;
}

.NavigationItem a:active,
.NavigationItem a.active {
    color: white;
    border-left: .5rem solid #fdbb11;
    text-decoration: none;
    background: rgba(72,19,118,.2);
    opacity: 1;
}
