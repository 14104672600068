@import "../../../sass/colors";

.content div {
  border: none; }
.body {
  border-top: 6px solid #FF0606 !important;
  outline: 0 !important;
  box-shadow: none !important; }
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 0 2rem 0;
  .title {
    font-size: 2.8rem; }
  .paragraph {
    margin: 1rem 0;
    font-size: 1.8rem; }
  .logo {
    margin: 4rem 0 5rem 0;
    width: 9.5rem;
    height: 9.5rem; }
  .confirmBtn {
    background-image: linear-gradient(45deg, rgba(254,190,25,1) 0%, rgba(254,184,23,1) 46%, rgba(254,173,18,1) 100%);
    border: none;
    width: 16rem;
    margin: 0 1rem;
    height: 4.5rem;
    font-size: 1.4rem;
    outline: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4rem;
    color: white;
    transition: all .2s;
    outline: none !important; }
  .confirmBtn:hover {
    transform: translateY(-3px);
    box-shadow: $box-shadow-btn-hover; }
  .confirmBtn:active {
    box-shadow: $box-shadow-btn-active;
    transform: translateY(-1px); } }
