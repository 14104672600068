$color-light-yellow: #FFF8E5;
$color-shopx-main-theme: #FEBE19;
$color-shopx-main-background: #FBFBFB;
$color-yellow-gradient-1: #FEBE19;
$color-yellow-gradient-2: #FEB817;
$color-yellow-gradient-3: #FEAD12;
$color-black-text: #2D2D2D;
$color-light-black-subheader: #5D5D5D;
$color-grey-paragraph: #B1B1B1;
$color-green-status: #7ED321;
$color-purple-status: #9409B1;
$color-border: #E3E3E3;
$bg-largest: 85.625em; //1370
$bg-largest-2: 80em; //1280
$bg-large: 68.75em; //1100
$bg-medium-1: 56.25em; //900
$bg-medium-2: 46.875em; //750
$bg-small: 37.5em; //600
$bg-smallest-1: 31.25em; //500
$bg-smallest-2: 25em; //400
$bg-smallest-3: 20em; //321
$box-shadow:  0 12px 24px 0 rgba(0, 23, 59, 0.1);
$box-shadow-light: 0px 10px 7px -3px rgba(82,82,82,0.07);
$box-shadow-btn-active: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
$box-shadow-btn-hover: 0 3px 6px 0 rgba(0, 23, 59, 0.1);
