@import "../../../sass/colors";

.width {
  width: 40rem; }
.wrapper {
  display: flex;
  flex-direction: column;
  background: white;
  width: 60%;
  border: solid 1px #ebebeb;
  border-radius: 10px;
  padding: 2.5rem 3.5rem 0;
  margin: 6rem 0 5rem;
  .separator {
    height: 1px;
    width: 100%;
    background: #ebebeb;
    border-radius: 10px;
    margin: 3rem 0; }
  .info {
    color: #2d2d2d;
    font-size: 1.5rem;
    margin: .5rem 0 0; }
  .dirCol {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    margin: 2rem 0 4rem; }
  .switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0 4rem;
    .textWrapper {
      display: flex;
      flex-direction: column;
      .switchH {
        color: #2d2d2d;
        font-size: 1.6rem;
        font-weight: 900; }
      .switchD {
        color: #2d2d2d;
        font-size: 1.2rem; } } }
  .header {
    color: #222;
    font-size: 2.4rem;
    font-weight: 900;
    letter-spacing: .64px; }
  .para {
    color: #2d2d2d;
    font-size: 1.6rem;
    margin: 0 0 3.5rem; } }

